import { helpers } from "@vuelidate/validators";
import messages from "./messages";

import {
  _lowerCase,
  _numericCharacter,
  _specialCharacter,
  _upperCase,
  _onlyNumbers,
  _onlyAlpha,
} from "./validators";

const upperCase = helpers.withMessage(messages.upperCase, _upperCase);
const lowerCase = helpers.withMessage(messages.lowerCase, _lowerCase);
const numericCharacter = helpers.withMessage(
  messages.numeric,
  _numericCharacter
);
const specialCharacter = helpers.withMessage(
  messages.symbol,
  _specialCharacter
);
const onlyNumbers = helpers.withMessage(messages.numeric, _onlyNumbers);
const onlyAlpha = helpers.withMessage(messages.alpha, _onlyAlpha);

export {
  upperCase,
  lowerCase,
  numericCharacter,
  specialCharacter,
  onlyNumbers,
  onlyAlpha,
};
