
import { Options, Vue } from "vue-class-component";
import useVuelidate from "@vuelidate/core";
import {
  upperCase,
  numericCharacter,
  lowerCase,
  specialCharacter,
} from "@/validators";
import { email, minLength, maxLength } from "@vuelidate/validators";
import { authModule } from "@/store";
import { UserInterface, ResponseErrorInterface } from "@/types";

@Options({
  name: "Login",
  components: {},
  validations: {
    formData: {
      email: {
        email,
      },
      password: {
        minLength: minLength(7),
        maxLength: maxLength(32),
        upperCase,
        lowerCase,
        specialCharacter,
        numericCharacter,
      },
    },
  },
})
export default class Login extends Vue {
  private formData: UserInterface = {
    email: "",
    password: "",
  };
  private v$ = useVuelidate();

  private get getValidator(): any {
    return this.v$;
  }

  private get getFormModel(): UserInterface {
    return this.getValidator.formData.$model;
  }

  private get isEmptyValues(): boolean {
    return Object.values(this.getFormModel).some((v) => !v);
  }

  private get isValidInputs(): boolean {
    return !this.getValidator.$invalid;
  }

  private get isDisabled(): boolean {
    return this.isEmptyValues || !this.isValidInputs;
  }

  private get getAuthError(): ResponseErrorInterface | null {
    return authModule.getAuthError;
  }

  private submit(): void {
    if (Object.keys(this.getFormModel).length) {
      authModule.Login(this.getFormModel);

      authModule.setError(null);
      this.getValidator.formData.$reset();
    }
  }

  created(): void {
    authModule.setError(null);
  }
}
