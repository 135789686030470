import { helpers } from "@vuelidate/validators";

export const _upperCase = (value) => {
  const regex = helpers.regex(/^(?=.*[A-Z])/);

  return !helpers.req(value) || regex(value);
};

export const _lowerCase = (value) => {
  const regex = helpers.regex(/^(?=.*[a-z])/);

  return !helpers.req(value) || regex(value);
};

export const _specialCharacter = (value) => {
  const regex = helpers.regex(/[-@!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/);

  return !helpers.req(value) || regex(value);
};

export const _numericCharacter = (value) => {
  const regex = helpers.regex(/^(?=.*[0-9])/);

  return !helpers.req(value) || regex(value);
};

export const _onlyNumbers = (value) => {
  const regex = helpers.regex(/^\d*\.?\d*$/);

  return !helpers.req(value) || regex(value);
};

export const _onlyAlpha = (value) => {
  return (
    !helpers.req(value) ||
    (!_numericCharacter(value) && !_specialCharacter(value))
  );
};
