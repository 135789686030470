import { UserInterface, urlsInterfaceBase } from "@/types";
import {
  httpClient,
  HttpClientDeleteParameters,
  HttpClientGetParameters,
  HttpClientPostParameters,
  HttpClientPutParameters,
} from "@/models/http/httpClient.models";

export interface UsersApiClientInterface {
  fetch: () => Promise<UserInterface[]>;
  edit: (data: UserInterface) => Promise<UserInterface>;
  add: (data: UserInterface) => Promise<UserInterface>;
  delete: (ids: Array<string | undefined>) => Promise<UserInterface>;
}

export class UsersApi implements UsersApiClientInterface {
  private readonly urls!: urlsInterfaceBase;

  constructor(urls: urlsInterfaceBase) {
    this.urls = urls;
  }

  fetch(): Promise<UserInterface[]> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch,
      requiresToken: true,
    };

    return httpClient.get<UserInterface[]>(getParameters);
  }

  edit(payload: UserInterface): Promise<UserInterface> {
    const PutParameters: HttpClientPutParameters = {
      url: this.urls.edit,
      payload: payload,
      requiresToken: true,
    };

    return httpClient.put<UserInterface>(PutParameters);
  }

  add(payload: UserInterface): Promise<UserInterface> {
    const PostParameters: HttpClientPostParameters = {
      url: this.urls.add,
      payload: payload,
      requiresToken: true,
    };

    return httpClient.post<UserInterface>(PostParameters);
  }

  delete(ids: Array<string | undefined>): Promise<UserInterface> {
    const DeleteParameters: HttpClientDeleteParameters = {
      url: this.urls.delete,
      payload: ids,
      requiresToken: true,
    };

    return httpClient.delete<UserInterface>(DeleteParameters);
  }
}
