import { ApiClientModels } from "@/models/api/apiClient.models";
import settingsApiClient from "@/apiClient/models/settings";
import languagesApiClient from "@/apiClient/models/languages";
import menuApiClient from "@/apiClient/models/menu";
import usersApiClient from "@/apiClient/models/users";
import authApiClient from "@/apiClient/models/auth";
import mediaApiClient from "@/apiClient/models/media";

const apiModelsClient: ApiClientModels = {
  settings: settingsApiClient,
  languages: languagesApiClient,
  menu: menuApiClient,
  users: usersApiClient,
  auth: authApiClient,
  media: mediaApiClient,
};

export default apiModelsClient;
