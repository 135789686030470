import { authUrlsInterface } from "@/types";
import {
  AuthApiClientInterface,
  AuthApi,
} from "@/models/api/auth/authApiClient.models";

const urls: authUrlsInterface = {
  login: `${process.env.VUE_APP_API_URL}/auth/login`,
  logout: `${process.env.VUE_APP_API_URL}/auth/logout`,
};

const authApiClient: AuthApiClientInterface = new AuthApi(urls);

export default authApiClient;
