import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { HTTP } from "@/types";
import api from "@/apiClient";

@Module
export default class MediaModule extends VuexModule {
  private globalLoader = false;
  private media: any = [];
  private errors: any = {};

  @Action
  public Upload(payload: any): void {
    this.setGlobalLoader(true);

    api.media
      .upload(payload)
      .then((response: any) => {
        if (response.status === HTTP.Success) {
          this.setMedia(response.file);
        }
        this.setGlobalLoader(false);
      })
      .catch((e) => {
        this.setErrors(e);
      });
  }

  @Action
  public Remove({ src, model, callback }: { src: string; model: string, callback: any }): void {
    this.setGlobalLoader(true);

    api.media
      .remove(src, model)
      .then((response: any) => {
        if (response.status === HTTP.Success) {
          this.setMedia("");
          callback();
        }

        this.setGlobalLoader(false);
      })
      .catch((e: any) => {
        this.setErrors(e);
      });
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  @Mutation
  public setErrors(error: any) {
    this.errors = error;
  }

  @Mutation
  public setMedia(media: any) {
    this.media = media;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }

  get getMedia() {
    return this.media;
  }

  get getErrors() {
    return this.errors;
  }
}
