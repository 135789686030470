import { createStore } from "vuex";

import AuthAdminModule from "@/store/modules/authAdmin.store";
import LanguagesAdminModule from "@/store/modules/languagesAdmin.store";
import SettingsAdminModule from "@/store/modules/settingsAdmin.store";
import MenuAdminModule from "@/store/modules/menuAdmin.store";
import UsersAdminModule from "./modules/usersAdmin.store";
import MediaModule from "@/store/modules/mediaAdmin.store";

const store = createStore({});

export const authModule = new AuthAdminModule({ store, name: "authAdmin" });
export const languagesModule = new LanguagesAdminModule({
  store,
  name: "languagesAdmin",
});
export const menuModule = new MenuAdminModule({ store, name: "menuAdmin" });
export const settingsModule = new SettingsAdminModule({
  store,
  name: "settingsAdmin",
});
export const usersModule = new UsersAdminModule({ store, name: "usersAdmin" });
export const mediaModule = new MediaModule({ store, name: "mediaAdmin" });

export default store;
