import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { copy, findRecursivelyValByKey } from "@/utils";
import {
  SettingsInterface,
  SettingsItem,
  DefaultLanguage,
  SettingsTabs,
  VisualisationsInterface,
  SettingsResponseInterface,
  PropertiesInterface,
} from "@/types";
import api from "@/apiClient";
import { languagesModule } from "@/store";

const getSettingsPath = (settings: SettingsInterface, tab: number): string => {
  if (tab === SettingsTabs.Visualisations) {
    return SettingsTabs[tab].toLowerCase();
  }

  const keys: Array<keyof SettingsInterface> = Object.keys(settings) as Array<keyof SettingsInterface>;
  const activeTabKey: keyof SettingsInterface = keys[tab];

  return activeTabKey;
}

@Module
export default class SettingsModule extends VuexModule {
  private globalLoader = false;
  private active: SettingsItem = { id: "", title: "" };
  private activeTab: number = 0;
  private visualisations: VisualisationsInterface = {
    figure: {},
    head: {},
    hand: {},
    foot: {},
  };
  private settings: SettingsInterface = {
    gender: [],
    part: [],
    type: [],
    properties: {
      figure: [],
      head: [],
      hand: [],
      foot: [],
    },
    unit: [],
  };

  @Action
  public Load(): void {
    this.setGlobalLoader(true);
    api
      .settings
      .fetch(languagesModule.getActiveLanguage)
      .then((data: SettingsInterface) => {
        if (!data) {
          throw Error("Data must be a object!");
        }

        this.setSettings({ settings: data });
        this.setGlobalLoader(false);
      });
  }

  @Action
  public LoadVisualisations(): void {
    this.setGlobalLoader(true);
    api
      .settings
      .fetchVisualisations()
      .then((data: VisualisationsInterface) => {
        if (!data) {
          throw Error("Data must be a object!");
        }

        this.setVisualisations({ visualisations: data });
        this.setGlobalLoader(false);
      });
  }

  @Action
  public Add({
     payload,
     callback,
   }: {
    payload: any;
    callback: any
  }): void {
    this.setGlobalLoader(true);

    const path = getSettingsPath(this.getSettings, this.getActiveTab);
    let optionsKey = {};

    if (this.getActiveTab === SettingsTabs.Visualisations) {
      optionsKey = {
        part_key: payload.part_key,
        type_key: payload.type_key,
        gender_key: payload.gender_key
      }
    }
    if (!path) console.error("Path is not correct!")

    delete payload.part_key;
    delete payload.type_key;
    delete payload.gender_key;
    delete payload.activeProperty;
    delete payload.activeTab;
    delete payload.id;

    api.settings.add(path, {
      ...payload,
      ...{
        locale: languagesModule.getActiveLanguage || DefaultLanguage.code,
      },
    }).then((data: SettingsInterface) => {
      const tabKey = SettingsTabs[this.getActiveTab].toLowerCase();

      if (this.getActiveTab === SettingsTabs.Visualisations) {
        this.setVisualisations({
          visualisations: { ...data, ...optionsKey },
          update: true
        });
      } else {
        this.setSettings({ settings: { [tabKey]: [data] }, update: true })
      }

      this.setGlobalLoader(false);
      callback(data);
    }).catch((e) => {
      callback(e);
    });
  }

  @Action
  public Edit({
    id,
    payload,
    callback,
  }: {
    id: string;
    payload: any;
    callback: any;
  }): void {
    this.setGlobalLoader(true);

    const path = getSettingsPath(this.getSettings, this.getActiveTab);

    if (!path) console.error("Path is not correct!")

    delete payload.activeProperty;
    delete payload.activeTab;

    api.settings.edit(id, path, {
      ...payload,
      ...{
        locale: languagesModule.getActiveLanguage || DefaultLanguage.code,
      },
    }).then((data: SettingsInterface) => {
      const tabKey = SettingsTabs[this.getActiveTab].toLowerCase();

      if (this.getActiveTab === SettingsTabs.Visualisations) {
        this.setVisualisations({ visualisations: data, update: true });
      } else {
        this.setSettings({ settings: { [tabKey]: [data] }, update: true })
      }

      this.setGlobalLoader(false);
      callback(data);
    })
      .catch((e) => {
        callback(e);
      });
  }

  @Action
  public Delete({
    id,
    callback,
  }: {
    id: string | undefined;
    callback: any;
  }) {
    const path = getSettingsPath(this.getSettings, this.getActiveTab);

    if (!path) console.error("Path is not correct!")

    api
      .settings
      .delete(id, path).then((response: any) => {
      //todo: update settings
      callback();
    })
      .catch((e) => {
        callback(e);
      });
  }

  @Action
  public ActiveTab(tab: number): void {
    this.setActiveTab(tab)
  }

  @Action
  public Sort(ids: Array<string>): void {
    const iDs = copy(ids);
    const path = getSettingsPath(this.getSettings, this.getActiveTab);

    this.setGlobalLoader(true);

    api
    .settings
    .sort({ iDs }, path)
    .then((data: any) => {
      this.setGlobalLoader(false);
    })
    .catch((e) => {
      console.log(e);
    });
  }

  @Mutation
  private setActive(active: SettingsItem) {
    this.active = active;
  }

  @Mutation
  private setActiveTab(tab: number) {
    this.activeTab = tab;
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  @Mutation
  public setSettings({
   settings,
   update
  }: {
    settings: SettingsResponseInterface,
    update?: boolean
  }) {
    if (update) {
      for (const key in settings) {
        const oldSetting = this.settings[key as keyof SettingsInterface];
        const [settingItem] = settings[key as keyof SettingsInterface] as SettingsItem[];

        if (Array.isArray(oldSetting)) {
          const index = oldSetting.findIndex((s: SettingsItem) => s.id === settingItem.id);

          if (index > -1) {
            oldSetting[index] = settingItem;
          }
        } else {
          for (const key in oldSetting) {
            const item = oldSetting[key as keyof PropertiesInterface];
            const index = item.findIndex((i: SettingsItem) => i.id === settingItem.id);
            item[index] = settingItem;
          }
        }
      }
    } else {
      this.settings = settings as SettingsInterface;
    }
  }

  @Mutation
  public setVisualisations({
   visualisations,
   update
 }: {
    visualisations: any,
    update?: boolean
  }) {
    if (update) {
      const isOptionsKeys = [visualisations.part_key, visualisations.type_key, visualisations.gender_key]

      if (isOptionsKeys.every(k => k)) {
        const pKey = visualisations.part_key as keyof VisualisationsInterface;
        const tKey = visualisations.type_key as keyof VisualisationsInterface;
        const gKey = visualisations.gender_key as keyof VisualisationsInterface;

        if (!this.visualisations[pKey]) this.visualisations[pKey] = {}
        if (!this.visualisations[pKey][tKey]) this.visualisations[pKey][tKey] = {};
        if (!this.visualisations[pKey][tKey][gKey]) this.visualisations[pKey][tKey][gKey] = {};

        this.visualisations[pKey][tKey][gKey] = visualisations;
      } else {
        for (const part in this.visualisations) {
          const pKey = part as keyof VisualisationsInterface;
          for (const type in this.visualisations[pKey]) {
            for (const gender in this.visualisations[pKey][type]) {
              if (this.visualisations[pKey][type][gender].id === visualisations.id) {
                this.visualisations[pKey][type][gender] = visualisations;
              }
            }
          }
        }
      }
    } else {
      this.visualisations = visualisations as VisualisationsInterface;
    }
  }

  get getActiveTab(): number {
    return this.activeTab;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }

  get getSettings(): SettingsInterface {
    return this.settings;
  }

  get getVisualisations(): VisualisationsInterface {
    return this.visualisations;
  }
}
