export default {
  required: "{attribute} is required",
  email: "invalid {attribute} address",
  minLength: "value is not available. Minimum {min} characters required",
  maxLength: "the length of {attribute} must be {max} characters or fewer",
  sameAsPassword: "the {attribute} confirmation does not match",
  upperCase:
    "The value must contain at least 1 uppercase alphabetical character",
  lowerCase:
    "The value must contain at least 1 lowercase alphabetical character",
  numeric: "The value must contain at least 1 numeric character",
  symbol: "The value must contain at least one special character",
  alpha: "The value must contain alphabetical characters",
};
