import { createApp } from "vue/dist/vue.esm-bundler.js";
import { UserInterface } from "@/types";
import App from "@/App.vue";
import router from "@/router";
import store, { authModule } from "@/store";
import mitt from "mitt";
import BalmUI, { useAlert } from "balm-ui";
import BalmUIPlus from "balm-ui/dist/balm-ui-plus.js";
import "balm-ui-css";
import "@/scss/main.scss";

const $alert = useAlert();

router.beforeEach((to, from, next) => {
  const currentUser: UserInterface | null = authModule.getAuthUser;
  const meta: any = to.meta;

  if (to.name !== "Login" && !authModule.isAuthenticated) {
    next({ name: "Login" });
  } else if (to.name === "Login" && authModule.isAuthenticated) {
    next({ name: "Dashboard" });
  } else {
    if (
        meta.authorize &&
        !meta.authorize.includes(currentUser?.role)
    ) {
      $alert({
        message: "Access denied!",
        state: "info",
        stateOutlined: true,
      }).then(() => {
        router.go(-1);
      });
    }

    next();
  }
});

const emitter = mitt();
const app = createApp(App).use(store).use(router).use(BalmUI).use(BalmUIPlus);

app.config.globalProperties.emitter = emitter;
app.mount("#app");
