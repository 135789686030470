import { urlsInterface } from "@/types";
import {
  LanguagesApiClientInterface,
  LanguagesApi,
} from "@/models/api/language/languageApiClient.models";

const urls: urlsInterface = {
  fetch: `${process.env.VUE_APP_API_URL}/languages`,
  edit: `${process.env.VUE_APP_API_URL}/languages`,
  add: `${process.env.VUE_APP_API_URL}/languages`,
  delete: `${process.env.VUE_APP_API_URL}/languages`,
  sort: `${process.env.VUE_APP_API_URL}/languages/sort`,
};

const languagesApiClient: LanguagesApiClientInterface = new LanguagesApi(urls);

export default languagesApiClient;
