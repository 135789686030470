
import { Options, Vue } from "vue-class-component";
import MainLayout from "@/layout/index.vue";
import {
  settingsModule,
  languagesModule,
  menuModule,
  usersModule,
} from "@/store";

@Options({
  name: "App",
  components: {
    MainLayout,
  },
  watch: {
    $route: {
      handler(to, from) {
        document.title = to.meta.title
          ? `Admin | ${to.meta.title}`
          : "Admin | Anatomy Next";
      },
    },
  },
})
export default class App extends Vue {
  beforeCreate(): void {
    settingsModule.Load();
    languagesModule.Load();
    menuModule.Load();
    usersModule.Load();
  }
}
