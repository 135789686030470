import { AES, BASE64, JWT } from "@/utils/crypto";

const cryptoTypes: any = { AES, BASE64, JWT };

export const encrypt = (data: string, type: string) => {
  if (!cryptoTypes[type] || !type) {
    throw Error(`Encrypt type: '${type}' not found!`);
  }

  if (!data) throw Error(`Data not found!`);

  return cryptoTypes[type]().encrypt(data);
};

export const decrypt = (data: string, type: string) => {
  if (!cryptoTypes[type] || !type) {
    throw Error(`Decrypt type: '${type}' not found!`);
  }

  if (!data) throw Error(`Data not found!`);

  return cryptoTypes[type]().decrypt(data);
};

export const copy = (obj: any): any => {
  return JSON.parse(JSON.stringify(obj));
};

export const getBreakpoint = (): string => {
  const mediaType = "min";
  const xl_mq = window.matchMedia(`(${mediaType}-width: 1200px)`);
  const lg_mq = window.matchMedia(`(${mediaType}-width: 992px)`);
  const md_mq = window.matchMedia(`(${mediaType}-width: 768px)`);
  const sm_mq = window.matchMedia(`(${mediaType}-width: 576px)`);

  if (xl_mq.matches) {
    return "xl";
  }

  if (lg_mq.matches) {
    return "lg";
  }

  if (md_mq.matches) {
    return "md";
  }

  if (sm_mq.matches) {
    return "sm";
  }

  return "xs";
};

export const jsonToQueryString = (params: {
  [key: string]: number | string;
}) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const search = ({
                         sourceData,
                         str,
                       }: {
  sourceData: any;
  str: string;
}) => {
  if (str.length < 3) return sourceData;

  return sourceData.filter((data: any) => {
    return Object.values(data).find(
      (d: any) => isNaN(d) && d.toLowerCase().indexOf(str.toLowerCase()) > -1
    );
  });
};

export const lengthOf = (array: Array<Array<number>>): number => {
  let len = 0;
  for (const arr of array) {
    len += arr.length;
  }
  return len;
};

export const stringFirstChar = (str: string): string => {
  if (!str) return "";

  const getChar = (s: string) => {
    if (!s) return "";

    return s.split("")[0].charAt(0).toUpperCase();
  }

  if (str.indexOf("_")) {
    const [first, last] = str.split("_");
    return getChar(first) + getChar(last);
  }
  return getChar(str);
}

export const titleCase = (str: string) => {
  const splitStr = str.toLowerCase().split(" ");

  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }

  return splitStr.join(" ");
};

export const elementReady = (selector: string) => {
  return new Promise((resolve, reject) => {
    let el = document.querySelector(selector);
    if (el) resolve(el);

    new MutationObserver((mutationRecords, observer) => {
      Array.from(document.querySelectorAll(selector)).forEach((element) => {
        resolve(element);
        observer.disconnect();
      });
    }).observe(document.documentElement, {
      childList: true,
      subtree: true,
    });
  });
};

export const findRecursivelyValByKey = (object: any, key: string) => {
  let value;

  Object.keys(object).some(function (k) {
    if (k === key) {
      value = object[k];
      return true;
    }

    if (object[k] && typeof object[k] === 'object') {
      value = findRecursivelyValByKey(object[k], key);
      return value !== undefined;
    }
  });

  return value;
}

export const getSiblings = (e: any) => {
  let siblings: any = [];
  if (!e.parentNode) return siblings;

  let sibling = e.parentNode.firstChild;

  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== e) siblings.push(sibling);
    sibling = sibling.nextSibling;
  }

  return siblings;
};
