import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { LanguagesInterface } from "@/types";
import { copy } from "@/utils";
import api from "@/apiClient";

@Module
export default class LanguagesModule extends VuexModule {
  private globalLoader = false;
  private languages: LanguagesInterface[] = [];
  private activeLanguage = "";

  @Action
  public Load(): void {
    this.setGlobalLoader(true);

    api.languages.fetch().then((data: LanguagesInterface[]) => {
      if (!data || !Array.isArray(data)) {
        throw Error("Data must be a array!");
      }

      this.setLanguages({ languages: data });
      this.setGlobalLoader(false);
    });
  }

  @Action
  public Add({
    language,
    callback,
  }: {
    language: LanguagesInterface;
    callback: any;
  }): void {
    this.setGlobalLoader(true);

    api.languages
      .add(language)
      .then((data: LanguagesInterface) => {
        this.setLanguages({ languages: [data], update: true });
        this.setGlobalLoader(false);
        callback();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  @Action
  public Edit({
    id,
    payload,
    callback,
  }: {
    id: string;
    payload: LanguagesInterface;
    callback: any;
  }): void {
    this.setGlobalLoader(true);

    api.languages
      .edit(id, payload)
      .then((data: LanguagesInterface) => {
        this.setLanguages({ languages: [data], update: true });
        this.setGlobalLoader(false);
        callback();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  @Action
  public Delete(id: string | undefined) {
    const data = this.getLanguages.filter((d) => d.id !== id);

    api.languages.delete(id).then((response: LanguagesInterface) => {
      this.setLanguages({ languages: data });
    });
  }

  @Action
  public Sort(ids: Array<string>): void {
    const iDs = copy(ids);

    this.setGlobalLoader(true);

    api.languages
      .sort({ iDs })
      .then((data: LanguagesInterface[]) => {
        if (!data || !Array.isArray(data)) {
          throw Error("Data must be a array!");
        }

        this.setGlobalLoader(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  @Action
  public ActiveLanguage(id: string | undefined): void {
    this.setActiveLanguage(id);
  }

  @Mutation
  private setLanguages({
    languages,
    update,
  }: {
    languages: LanguagesInterface[];
    update?: boolean;
  }) {
    if (update) {
      const [language] = languages;
      const newLanguages: LanguagesInterface[] = copy(this.languages);
      const langIndex = newLanguages.findIndex(
        (lang) => lang.id === language.id
      );

      if (langIndex > -1) {
        newLanguages[langIndex] = language;
      } else {
        newLanguages.push(language);
      }

      this.languages = newLanguages;
    } else {
      this.languages = languages;
    }
  }

  @Mutation
  public setActiveLanguage(id: string | undefined) {
    const [language] = this.languages.filter(
      (l: LanguagesInterface) => l.id === id
    );

    this.activeLanguage = language ? language.code : "";
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  get getLanguages() {
    return this.languages;
  }

  get getActiveLanguage() {
    return this.activeLanguage;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }
}
