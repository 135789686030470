import { mediaUrlsInterface } from "@/types";
import {
  MediaApiClientInterface,
  MediaApi,
} from "@/models/api/media/mediaApiClient.models";

const urls: mediaUrlsInterface = {
  upload: `${process.env.VUE_APP_API_URL}/media/upload`,
  remove: `${process.env.VUE_APP_API_URL}/media/remove`,
};

const mediaApiClient: MediaApiClientInterface = new MediaApi(urls);

export default mediaApiClient;
