export enum Direction {
  Left = -1,
  Right = 1,
}

export enum AssetType {
  Models = 1,
  Image = 2,
}

export enum Roles {
  Admin = "admin",
  User = "user",
}

export enum Auth {
  Logout = 1,
}

export enum HTTP {
  Unauthorized = 401,
  NotFound = 404,
  BadRequest = 400,
  Success = 200,
}

export enum SettingsTabs {
  Gender = 0,
  Part = 1,
  Type = 2,
  Properties = 3,
  Unit = 4,
  Visualisations = 5,
}

export enum DefaultLanguage {
  code = "en",
}
