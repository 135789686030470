import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { HTTP } from "@/types";
import { authModule } from "@/store";
import router from "@/router";

/**
 * @description
 * Interface for our HttpClient wrapper
 */
export interface HttpClientModels {
  get<T>(parameters: HttpClientGetParameters): Promise<T>;

  post<T>(parameters: HttpClientPostParameters): Promise<T>;

  put<T>(parameters: HttpClientPostParameters): Promise<T>;

  delete<T>(parameters: HttpClientPostParameters): Promise<T>;
}

/**
 * HttpClient parameters for "get" operations
 */
export interface HttpClientGetParameters {
  url: string;
  requiresToken: boolean;
}

/**
 * HttpClient parameters for "post" operations
 */
export interface HttpClientPostParameters {
  url: string;
  payload: any;
  requiresToken: boolean;
}

/**
 * HttpClient parameters for "put" operations
 */
export interface HttpClientPutParameters {
  url: string;
  payload: any;
  requiresToken: boolean;
}

/**
 * HttpClient parameters for "put" operations
 */
export interface HttpClientDeleteParameters {
  url: string;
  payload?: any;
  requiresToken: boolean;
}

/**
 * @description
 * Wraps http client functionality to avoid directly using a third party npm package like axios
 * and simplify replacement in the future if such npm package would stop being developed or other reasons
 */
import { decrypt } from "@/utils";

export class HttpClient implements HttpClient {
  private getToken(): string {
    const TOKEN_KEY =
      process.env && process.env.VUE_APP_TOKEN_KEY
        ? process.env.VUE_APP_TOKEN_KEY
        : "userToken";

    const localStorageData = localStorage.getItem(TOKEN_KEY) || "";
    return localStorageData
      ? JSON.parse(decrypt(localStorageData, "AES")).token.accessToken
      : "";
  }

  private axiosErrorHandling = (error: any) => {console.log(error)
    // if (error.response.status == HTTP.Unauthorized) {
    //   authModule.Logout();
    //   router.push({ name: "Login" });
    // }

    return Promise.reject(error);
  };

  constructor() {
    axios.interceptors.response.use(
      (response) => response,
      this.axiosErrorHandling
    );
  }

  get<T>(parameters: HttpClientGetParameters): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken } = parameters;

      // axios options
      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        const token = this.getToken();
        options.headers.Authorization = `Bearer ${token}`;
      }

      axios
        .get(url, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response: any) => {
          console.info("------ rejecting ----");
          reject(response);
        });
    });
  }

  post<T>(
    parameters: HttpClientPostParameters,
    optionsParameters = {}
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, payload, requiresToken } = parameters;

      // axios options
      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        const token = this.getToken();
        options.headers.Authorization = `Bearer ${token}`;
      }

      if (Object.keys(optionsParameters).length) {
        options.headers = { ...optionsParameters, ...options.headers };
      }

      axios
        .post(url, payload, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }

  put<T>(parameters: HttpClientPutParameters): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, payload, requiresToken } = parameters;

      // axios options
      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        const token = this.getToken();
        options.headers.Authorization = `Bearer ${token}`;
      }

      axios
        .put(url, payload, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }

  delete<T>(parameters: HttpClientDeleteParameters): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      const { url, requiresToken } = parameters;

      // axios options
      const options: AxiosRequestConfig = {
        headers: {},
      };

      if (requiresToken) {
        const token = this.getToken();
        options.headers.Authorization = `Bearer ${token}`;
      }

      axios
        .delete(url, options)
        .then((response: any) => {
          resolve(response.data as T);
        })
        .catch((response: any) => {
          reject(response);
        });
    });
  }
}

export const httpClient = new HttpClient();
