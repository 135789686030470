import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Roles } from "@/types/enum";
import Login from "@/views/Login.vue";

const description = "Human proportions calculator";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    meta: {
      title: "Login",
    },
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      title: "Dashboard",
      description,
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
  },
  {
    path: "/users",
    name: "Users",
    meta: {
      authorize: [Roles.Admin],
      title: "Users",
      description,
    },
    component: () =>
      import(/* webpackChunkName: "users" */ "@/views/Users.vue"),
    children: [
      {
        path: "/users/create",
        name: "Users/Create",
        meta: {
          authorize: [Roles.Admin],
          title: "Create user",
        },
        component: () =>
          import(
            /* webpackChunkName: "users" */ "@/components/forms/users/index.vue"
          ),
      },
      {
        path: "/users/edit",
        name: "Users/Edit",
        meta: {
          authorize: [Roles.Admin],
          title: "Edit user",
        },
        component: () =>
          import(
            /* webpackChunkName: "users" */ "@/components/forms/users/index.vue"
          ),
      },
    ],
  },
  {
    path: "/languages",
    name: "Languages",
    redirect: "/languages/list",
    meta: {
      title: "Languages",
      description,
    },
    component: () =>
      import(/* webpackChunkName: "languages" */ "@/views/Languages.vue"),
    children: [
      {
        path: "/languages/list",
        name: "Languages/List",
        meta: {
          title: "Languages list",
        },
        component: () =>
            import(
                /* webpackChunkName: "categories" */ "@/components/ui/lists/languages/index.vue"
                ),
      },
      {
        path: "/languages/create",
        name: "Languages/Create",
        meta: {
          authorize: [Roles.Admin],
          title: "Create language",
        },
        component: () =>
            import(
                /* webpackChunkName: "languages" */ "@/components/forms/languages/index.vue"
                ),
      },
      {
        path: "/languages/edit",
        name: "Languages/Edit",
        meta: {
          authorize: [Roles.Admin],
          title: "Edit language",
        },
        component: () =>
            import(
                /* webpackChunkName: "languages" */ "@/components/forms/languages/index.vue"
                ),
      },
    ],
  },
  {
    path: "/menu",
    name: "Menu",
    redirect: "/menu/list",
    meta: {
      title: "Menu",
      description,
    },
    component: () => import(/* webpackChunkName: "menu" */ "@/views/Menu.vue"),
    children: [
      {
        path: "/menu/list",
        name: "Menu/List",
        meta: {
          title: "Menu list",
        },
        component: () =>
            import(
                /* webpackChunkName: "categories" */ "@/components/ui/lists/menu/index.vue"
                ),
      },
      {
        path: "/menu/create",
        name: "Menu/Create",
        meta: {
          authorize: [Roles.Admin],
          title: "Create menu",
        },
        component: () =>
            import(
                /* webpackChunkName: "languages" */ "@/components/forms/menu/index.vue"
                ),
      },
      {
        path: "/menu/edit",
        name: "Menu/Edit",
        meta: {
          authorize: [Roles.Admin],
          title: "Edit menu",
        },
        component: () =>
            import(
                /* webpackChunkName: "languages" */ "@/components/forms/menu/index.vue"
                ),
      },
    ],
  },
  {
    path: "/settings",
    name: "Settings",
    redirect: "/settings/list",
    meta: {
      title: "Settings",
      description,
    },
    component: () =>
      import(/* webpackChunkName: "settings" */ "@/views/Settings.vue"),
    children: [
      {
        path: "/settings/list",
        name: "Settings/List",
        meta: {
          title: "Settings list",
        },
        component: () =>
            import(
                /* webpackChunkName: "settings" */ "@/components/ui/lists/settings/index.vue"
                ),
      },
      {
        path: "/settings/create/gender",
        name: "Settings/Create/gender",
        meta: {
          authorize: [Roles.Admin],
          title: "Create gender",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/index.vue"
          ),
      },
      {
        path: "/settings/create/part",
        name: "Settings/Create/part",
        meta: {
          authorize: [Roles.Admin],
          title: "Create part",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/index.vue"
          ),
      },
      {
        path: "/settings/create/type",
        name: "Settings/Create/type",
        meta: {
          authorize: [Roles.Admin],
          title: "Create type",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/index.vue"
          ),
      },
      {
        path: "/settings/create/properties",
        name: "Settings/Create/properties",
        meta: {
          authorize: [Roles.Admin],
          title: "Create properties",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/Properties.vue"
          ),
      },
      {
        path: "/settings/create/unit",
        name: "Settings/Create/unit",
        meta: {
          authorize: [Roles.Admin],
          title: "Create unit",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/index.vue"
          ),
      },
      {
        path: "/settings/edit/gender",
        name: "Settings/Edit/gender",
        meta: {
          authorize: [Roles.Admin],
          title: "Edit gender",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/index.vue"
          ),
      },
      {
        path: "/settings/edit/part",
        name: "Settings/Edit/part",
        meta: {
          authorize: [Roles.Admin],
          title: "Edit part",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/index.vue"
          ),
      },
      {
        path: "/settings/edit/type",
        name: "Settings/Edit/type",
        meta: {
          authorize: [Roles.Admin],
          title: "Edit type",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/index.vue"
          ),
      },
      {
        path: "/settings/edit/properties",
        name: "Settings/Edit/properties",
        meta: {
          authorize: [Roles.Admin],
          title: "Edit properties",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/Properties.vue"
          ),
      },
      {
        path: "/settings/edit/unit",
        name: "Settings/Edit/unit",
        meta: {
          authorize: [Roles.Admin],
          title: "Edit unit",
        },
        component: () =>
          import(
            /* webpackChunkName: "settings" */ "@/components/forms/settings/index.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
