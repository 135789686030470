import { jwtDecode } from "jwt-js-decode";

export const JWT = () => {
  return {
    type: "JWT",
    decrypt: (token: string) => {
      const jwt = jwtDecode(token);
      return jwt.payload;
    },
  };
};
