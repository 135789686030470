import {SettingsInterface, SettingsItem, urlsInterfaceExtended, VisualisationsInterface} from "@/types";
import {
  httpClient,
  HttpClientDeleteParameters,
  HttpClientGetParameters,
  HttpClientPostParameters,
  HttpClientPutParameters
} from "@/models/http/httpClient.models";

export interface SettingsApiClientInterface {
  fetch: (lang?: string) => Promise<SettingsInterface>;
  fetchVisualisations: () => Promise<VisualisationsInterface>;
  edit(id: string, path: string, payload: SettingsItem): Promise<SettingsInterface>;
  add(path: string, payload: SettingsItem): Promise<SettingsInterface>;
  delete: (id: string | undefined, path: string) => Promise<SettingsInterface>;
  sort: (ids: { [key: string]: string[] }, path: string | undefined) => Promise<SettingsInterface[]>;
}

export class SettingsApi implements SettingsApiClientInterface {
  private readonly urls!: urlsInterfaceExtended;

  constructor(urls: urlsInterfaceExtended) {
    this.urls = urls;
  }

  fetch(lang?: string): Promise<SettingsInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch + (lang ? `?locale=${lang}` : ""),
      requiresToken: true,
    };

    return httpClient.get<SettingsInterface>(getParameters);
  }

  fetchVisualisations(): Promise<VisualisationsInterface> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetchVisualisations,
      requiresToken: true,
    };

    return httpClient.get<VisualisationsInterface>(getParameters);
  }

  edit(id: string, path: string, payload: SettingsItem): Promise<SettingsInterface> {
    const PutParameters: HttpClientPutParameters = {
      url: `${this.urls.edit}/${path}/${id}`,
      payload: payload,
      requiresToken: true,
    };

    return httpClient.put<SettingsInterface>(PutParameters);
  }

  add(path: string, payload: SettingsItem): Promise<SettingsInterface> {
    const PostParameters: HttpClientPostParameters = {
      url: `${this.urls.add}/${path}`,
      payload: payload,
      requiresToken: true,
    };

    return httpClient.post<SettingsInterface>(PostParameters);
  }

  delete(id: string | undefined, path: string): Promise<SettingsInterface> {
    const DeleteParameters: HttpClientDeleteParameters = {
      url: `${this.urls.delete}/${path}/${id}`,
      requiresToken: true,
    };

    return httpClient.delete<SettingsInterface>(DeleteParameters);
  }

  sort(ids: { [key: string]: string[] }, path: string | undefined): Promise<SettingsInterface[]> {
    const PostParameters: HttpClientPostParameters = {
      url: `${this.urls.sort}/${path}/sort`,
      payload: ids,
      requiresToken: true,
    };

    return httpClient.post<SettingsInterface[]>(PostParameters);
  }
}
