import {
  AuthInterface,
  ResponseErrorInterface,
  LogoutInterface,
  authUrlsInterface,
  UserInterface,
} from "@/types";
import {
  httpClient,
  HttpClientPostParameters,
} from "@/models/http/httpClient.models";

export interface AuthApiClientInterface {
  login: (payload: UserInterface) => Promise<AuthInterface>;
  logout: () => Promise<LogoutInterface>;
}

export class AuthApi implements AuthApiClientInterface {
  private readonly urls!: authUrlsInterface;

  constructor(urls: authUrlsInterface) {
    this.urls = urls;
  }

  login(payload: UserInterface): Promise<AuthInterface> {
    const PostParameters: HttpClientPostParameters = {
      url: this.urls.login,
      payload: payload,
      requiresToken: false,
    };

    return httpClient.post<AuthInterface>(PostParameters);
  }

  logout(): Promise<LogoutInterface> {
    const PostParameters: HttpClientPostParameters = {
      url: this.urls.logout,
      payload: "",
      requiresToken: false,
    };

    return httpClient.post<LogoutInterface>(PostParameters);
  }
}
