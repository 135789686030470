import { MenuInterface, urlsInterface } from "@/types";
import {
  httpClient,
  HttpClientDeleteParameters,
  HttpClientGetParameters,
  HttpClientPostParameters,
  HttpClientPutParameters,
} from "@/models/http/httpClient.models";

export interface MenuApiClientInterface {
  fetch: (lang?: string) => Promise<MenuInterface[]>;
  edit: (id: string, data: MenuInterface) => Promise<MenuInterface>;
  add: (data: MenuInterface) => Promise<MenuInterface>;
  delete: (id: string | undefined) => Promise<MenuInterface>;
  sort: (ids: { [key: string]: string[] }) => Promise<MenuInterface[]>;
}

export class MenuApi implements MenuApiClientInterface {
  private readonly urls!: urlsInterface;

  constructor(urls: urlsInterface) {
    this.urls = urls;
  }

  fetch(lang?: string): Promise<MenuInterface[]> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch + (lang ? `?locale=${lang}` : ""),
      requiresToken: true,
    };

    return httpClient.get<MenuInterface[]>(getParameters);
  }

  edit(id: string, payload: MenuInterface): Promise<MenuInterface> {
    const PutParameters: HttpClientPutParameters = {
      url: `${this.urls.edit}/${id}`,
      payload: payload,
      requiresToken: true,
    };

    return httpClient.put<MenuInterface>(PutParameters);
  }

  add(payload: MenuInterface): Promise<MenuInterface> {
    const PostParameters: HttpClientPostParameters = {
      url: this.urls.add,
      payload: payload,
      requiresToken: true,
    };

    return httpClient.post<MenuInterface>(PostParameters);
  }

  delete(id: string | undefined): Promise<MenuInterface> {
    const DeleteParameters: HttpClientDeleteParameters = {
      url: `${this.urls.delete}/${id}`,
      requiresToken: true,
    };

    return httpClient.delete<MenuInterface>(DeleteParameters);
  }

  sort(ids: { [key: string]: string[] }): Promise<MenuInterface[]> {
    const PostParameters: HttpClientPostParameters = {
      url: this.urls.sort,
      payload: ids,
      requiresToken: true,
    };

    return httpClient.post<MenuInterface[]>(PostParameters);
  }
}
