import CryptoJS from "crypto-js";

export const BASE64 = () => {
  return {
    type: "base64",
    encrypt: (data: string) => {
      const wordArray = CryptoJS.enc.Utf8.parse(data);
      return CryptoJS.enc.Base64.stringify(wordArray);
    },
    decrypt: (data: string) => {
      const parsedWordArray = CryptoJS.enc.Base64.parse(data);
      return parsedWordArray.toString(CryptoJS.enc.Utf8);
    },
  };
};
