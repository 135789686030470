import { urlsInterfaceExtended } from "@/types";
import {
  SettingsApiClientInterface,
  SettingsApi,
} from "@/models/api/settings/settingsApiClient.models";

const urls: urlsInterfaceExtended = {
  fetch: `${process.env.VUE_APP_API_URL}/settings`,
  fetchVisualisations: `${process.env.VUE_APP_API_URL}/settings/visualisations`,
  edit: `${process.env.VUE_APP_API_URL}/settings`,
  add: `${process.env.VUE_APP_API_URL}/settings`,
  delete: `${process.env.VUE_APP_API_URL}/settings`,
  sort: `${process.env.VUE_APP_API_URL}/settings`,
};

const settingsApiClient: SettingsApiClientInterface = new SettingsApi(urls);

export default settingsApiClient;
