import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { UserInterface } from "@/types";
import api from "@/apiClient";
import { copy } from "@/utils";

@Module
export default class UsersModule extends VuexModule {
  private globalLoader = false;
  private users: UserInterface[] = [];

  @Action
  public Load(): void {
    this.setGlobalLoader(true);

    api.users.fetch().then((data: UserInterface[]) => {
      if (!data || !Array.isArray(data)) {
        throw Error("Data must be a array!");
      }

      this.setUsers({ users: data });
      this.setGlobalLoader(false);
    });
  }

  @Action
  public Add(payload: UserInterface): void {
    this.setGlobalLoader(true);

    api.users.add(payload).then((data: UserInterface) => {
      this.setUsers({ users: [data], update: true });
      this.setGlobalLoader(false);
    });
  }

  @Action
  public Edit({ id, payload }: { id: string; payload: UserInterface }): void {
    this.setGlobalLoader(true);
    api.users.edit(payload).then((data: UserInterface) => {
      this.setUsers({ users: [data], update: true });
      this.setGlobalLoader(false);
    });
  }

  @Action
  public Delete(ids: Array<string | undefined>) {
    const data = this.getUsers.filter((d) => !ids.includes(d.id));

    api.users.delete(ids).then((response: UserInterface) => {
      this.setUsers({ users: data });
    });
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  @Mutation
  private setUsers({
    users,
    update,
  }: {
    users: UserInterface[];
    update?: boolean;
  }) {
    if (update) {
      const [user] = users;
      const newUser: UserInterface[] = copy(this.users);
      const userIndex = newUser.findIndex((m) => m.id === user.id);

      if (userIndex > -1) {
        newUser[userIndex] = user;
      } else {
        newUser.push(user);
      }

      this.users = newUser;
    } else {
      this.users = users;
    }
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }

  get getUsers() {
    return this.users;
  }
}
