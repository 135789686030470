import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import router from "@/router";
import {
  AuthInterface,
  ResponseErrorInterface,
  UserInterface,
  LogoutInterface,
} from "@/types";
import api from "@/apiClient";
import { decrypt, encrypt } from "@/utils";

const TOKEN_KEY: string =
  process.env && process.env.VUE_APP_TOKEN_KEY
    ? process.env.VUE_APP_TOKEN_KEY
    : "userToken";

@Module
export default class AuthModule extends VuexModule {
  private globalLoader = false;
  private user: UserInterface | null = null;
  private error: ResponseErrorInterface | null = null;

  @Action
  public Login(credentials: UserInterface): void {
    this.setGlobalLoader(true);

    api.auth
      .login(credentials)
      .then((data: AuthInterface) => {
        this.User(data.user);
        this.setGlobalLoader(false);

        localStorage.setItem(TOKEN_KEY, encrypt(JSON.stringify(data), "AES"));
        router.push({ name: "Dashboard" });
      })
      .catch((e) => {
        this.setError(e);
      });
  }

  @Action
  public Logout(): void {
    this.setGlobalLoader(true);

    api.auth.logout().then((data: LogoutInterface) => {
      this.User(null);
      this.setGlobalLoader(false);

      localStorage.removeItem(TOKEN_KEY);
      router.push({ name: "Login" });
    });
  }

  @Action
  public User(user: UserInterface | null): void {
    this.setUser(user);
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  @Mutation
  public setError(error: ResponseErrorInterface | null) {
    this.error = error;
  }

  @Mutation
  private setUser(user: UserInterface | null) {
    this.user = user;
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }

  get isAuthenticated(): boolean {
    return !!localStorage.getItem(TOKEN_KEY);
  }

  get getAuthError(): ResponseErrorInterface | null {
    return this.error;
  }

  get getAuthUser(): UserInterface | null {
    if (!this.user) {
      const localStorageData = localStorage.getItem(TOKEN_KEY) || "";
      const user = localStorageData
        ? JSON.parse(decrypt(localStorageData, "AES")).user
        : null;

      return user;
    }

    return this.user;
  }
}
