import { MenuInterface, mediaUrlsInterface } from "@/types";
import {
  httpClient,
  HttpClientDeleteParameters,
  HttpClientPostParameters,
} from "@/models/http/httpClient.models";

export interface MediaApiClientInterface {
  upload: (payload: any) => Promise<any>;
  remove: (src: string, model: string) => Promise<any>;
}

export class MediaApi implements MediaApiClientInterface {
  private readonly urls!: mediaUrlsInterface;

  constructor(urls: mediaUrlsInterface) {
    this.urls = urls;
  }

  upload(file: any): Promise<any> {
    const formData = new FormData();
    formData.append("images", file);

    const options = {
      "Content-Type": `multipart/form-data;`,
    };

    const PostParameters: HttpClientPostParameters = {
      url: this.urls.upload,
      payload: formData,
      requiresToken: true,
    };

    return httpClient.post<any>(PostParameters, options);
  }

  remove(src: string, model: string): Promise<any> {
    const DeleteParameters: HttpClientDeleteParameters = {
      url: `${this.urls.remove}/${model}/${src}`,
      requiresToken: true,
    };

    return httpClient.delete<MenuInterface>(DeleteParameters);
  }
}
