import { urlsInterface } from "@/types";
import {
  MenuApiClientInterface,
  MenuApi,
} from "@/models/api/menu/menuApiClient.models";

const urls: urlsInterface = {
  fetch: `${process.env.VUE_APP_API_URL}/menu`,
  edit: `${process.env.VUE_APP_API_URL}/menu`,
  add: `${process.env.VUE_APP_API_URL}/menu`,
  delete: `${process.env.VUE_APP_API_URL}/menu`,
  sort: `${process.env.VUE_APP_API_URL}/menu/sort`,
};

const languagesApiClient: MenuApiClientInterface = new MenuApi(urls);

export default languagesApiClient;
