import { LanguagesInterface, urlsInterface } from "@/types";
import {
  httpClient,
  HttpClientDeleteParameters,
  HttpClientGetParameters,
  HttpClientPostParameters,
  HttpClientPutParameters,
} from "@/models/http/httpClient.models";

export interface LanguagesApiClientInterface {
  fetch: () => Promise<LanguagesInterface[]>;
  edit: (id: string, data: LanguagesInterface) => Promise<LanguagesInterface>;
  add: (data: LanguagesInterface) => Promise<LanguagesInterface>;
  delete: (id: string | undefined) => Promise<LanguagesInterface>;
  sort: (ids: { [key: string]: string[] }) => Promise<LanguagesInterface[]>;
}

export class LanguagesApi implements LanguagesApiClientInterface {
  private readonly urls!: urlsInterface;

  constructor(urls: urlsInterface) {
    this.urls = urls;
  }

  fetch(): Promise<LanguagesInterface[]> {
    const getParameters: HttpClientGetParameters = {
      url: this.urls.fetch,
      requiresToken: true,
    };

    return httpClient.get<LanguagesInterface[]>(getParameters);
  }

  edit(id: string, payload: LanguagesInterface): Promise<LanguagesInterface> {
    const PutParameters: HttpClientPutParameters = {
      url: `${this.urls.edit}/${id}`,
      payload: payload,
      requiresToken: true,
    };

    return httpClient.put<LanguagesInterface>(PutParameters);
  }

  add(payload: LanguagesInterface): Promise<LanguagesInterface> {
    const PostParameters: HttpClientPostParameters = {
      url: this.urls.add,
      payload: payload,
      requiresToken: true,
    };

    return httpClient.post<LanguagesInterface>(PostParameters);
  }

  delete(id: string | undefined): Promise<LanguagesInterface> {
    const DeleteParameters: HttpClientDeleteParameters = {
      url: `${this.urls.delete}/${id}`,
      requiresToken: true,
    };

    return httpClient.delete<LanguagesInterface>(DeleteParameters);
  }

  sort(ids: { [key: string]: string[] }): Promise<LanguagesInterface[]> {
    const PostParameters: HttpClientPostParameters = {
      url: this.urls.sort,
      payload: ids,
      requiresToken: true,
    };

    return httpClient.post<LanguagesInterface[]>(PostParameters);
  }
}
