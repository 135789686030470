import { urlsInterfaceBase } from "@/types";
import {
  UsersApiClientInterface,
  UsersApi,
} from "@/models/api/users/usersApiClient.models";

const urls: urlsInterfaceBase = {
  fetch: `${process.env.VUE_APP_API_URL}/users`,
  edit: `${process.env.VUE_APP_API_URL}/users/update`,
  add: `${process.env.VUE_APP_API_URL}/users`,
  delete: `${process.env.VUE_APP_API_URL}/users/delete`,
};

const usersApiClient: UsersApiClientInterface = new UsersApi(urls);

export default usersApiClient;
