import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { DefaultLanguage, MenuInterface } from "@/types";
import api from "@/apiClient";
import { copy } from "@/utils";
import { languagesModule } from "@/store";

@Module
export default class MenuModule extends VuexModule {
  private globalLoader = false;
  private menu: MenuInterface[] = [];

  @Action
  public Load(): void {
    this.setGlobalLoader(true);

    api.menu
      .fetch(languagesModule.getActiveLanguage)
      .then((data: MenuInterface[]) => {
        if (!data || !Array.isArray(data)) {
          throw Error("Data must be a array!");
        }

        this.setMenu({ menu: data });
        this.setGlobalLoader(false);
      });
  }

  @Action
  public Add({ menu, callback }: { menu: MenuInterface; callback: any }): void {
    this.setGlobalLoader(true);

    api.menu
      .add({
        ...menu,
        ...{
          locale: languagesModule.getActiveLanguage || DefaultLanguage.code,
        },
      })
      .then((data: MenuInterface) => {
        this.setMenu({ menu: [data], update: true });
        this.setGlobalLoader(false);
        callback();
      })
      .catch((e) => {
        callback(e);
      });
  }

  @Action
  public Edit({
    id,
    payload,
    callback,
  }: {
    id: string;
    payload: MenuInterface;
    callback: any;
  }): void {
    this.setGlobalLoader(true);

    api.menu
      .edit(id, {
        ...payload,
        ...{
          locale: languagesModule.getActiveLanguage || DefaultLanguage.code,
        },
      })
      .then((data: MenuInterface) => {
        this.setMenu({ menu: [data], update: true });
        this.setGlobalLoader(false);
        callback();
      })
      .catch((e) => {
        callback(e);
      });
  }

  @Action
  public Delete(id: string | undefined) {
    const data = this.getMenu.filter((d) => d.id !== id);

    api.menu.delete(id).then((response: MenuInterface) => {
      this.setMenu({ menu: data });
    });
  }

  @Action
  public Sort(ids: Array<string>): void {
    const iDs = copy(ids);

    this.setGlobalLoader(true);

    api.menu
      .sort({ iDs })
      .then((data: MenuInterface[]) => {
        if (!data || !Array.isArray(data)) {
          throw Error("Data must be a array!");
        }

        this.setGlobalLoader(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  @Mutation
  private setGlobalLoader(loader: boolean) {
    this.globalLoader = loader;
  }

  @Mutation
  private setMenu({
    menu,
    update,
  }: {
    menu: MenuInterface[];
    update?: boolean;
  }) {
    if (update) {
      const [mItem] = menu;
      const newMenu: MenuInterface[] = copy(this.menu);
      const menuIndex = newMenu.findIndex((m) => m.id === mItem.id);

      if (menuIndex > -1) {
        newMenu[menuIndex] = mItem;
      } else {
        newMenu.push(mItem);
      }

      this.menu = newMenu;
    } else {
      this.menu = menu;
    }
  }

  get getGlobalLoader(): boolean {
    return this.globalLoader;
  }

  get getMenu() {
    return this.menu;
  }
}
